import { Component } from "react";
import { type WithTranslation, withTranslation } from "react-i18next";
import {
  type GroupInfo,
  type GroupMemberInfo,
  isMemberAdmin,
  memberPermissions,
  adminPermissions,
  type ResponseSystemFields,
} from "../types/gigya";
import "../i18n/config";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  InputAdornment,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  useGridApiContext,
} from "@mui/x-data-grid";
import { Add, ArrowDropDown, Share } from "@mui/icons-material";
import { GigyaGroupModels } from "../types/gigya.schema";
import { transformExportedDesign } from "../muiConverter";
import P11440322HGMHomeAdminViewmemberstabedit from "./.exported_teleporthq/groups wip-react/src/views/p11440322h-g-m-home-admin-viewmemberstabedit";
import { ReactComponent as TrashSvg } from "./.exported_teleporthq/groups wip-react/public/playground_assets/trash6936-0alf.svg";
import ModalDialog from "./ModalDialog";
import { type ToastState, type ModalDialogState } from "../App";
import React from "react";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import { TableMembers } from "./TableMembers";

type GroupMembersProps = {
  clinicInfo?: GroupInfo<GigyaGroupModels.ClinicModel>;
  user: any;
  onDirty: (d: boolean) => void;
} & WithTranslation;
class GroupMembers extends Component<
  GroupMembersProps,
  {
    members?: GroupMemberInfo[];
    updatedMembers?: GroupMemberInfo[];
    updatedMembersStatus: GroupMemberInfo[];
    updatedMembersPermission: GroupMemberInfo[];
    modalDialog?: ModalDialogState;
    toast?: ToastState;
    spinner?: boolean;
    pendingMembers?: GroupMemberInfo[];
  }
> {
  constructor(props: GroupMembersProps) {
    super(props);
    this.state = {
      updatedMembersStatus: [],
      updatedMembersPermission: [],
    };
  }

  componentDidMount() {
    this.fetchMembers();
  }

  componentDidUpdate(prevProps: GroupMembersProps) {
    if (
      this.props.clinicInfo &&
      (!this.state ||
        prevProps.clinicInfo?.groupId !== this.props.clinicInfo.groupId)
    ) {
      this.setState({
        members: undefined,
        updatedMembers: undefined,
        pendingMembers: undefined,
      });
      this.fetchMembers();
    }
  }

  handleModalDialogClose = () => {
    this.setState({ modalDialog: { open: false } });
  };

  handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ toast: { open: false } });
  };

  fetchMembers() {
    this.setState({ spinner: true });
    window.gigya.accounts.getJWT({
      UID: this.props.user.UID,
      callback: (resp) => {
        if (resp["errorCode"] === 0) {
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${resp.id_token}`,
          };
          const apiKey = process.env.REACT_APP_CDC_API_KEY ?? "";
          const GetMembersUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/getMembers`;
          const GetMembersUrlTW = `${process.env.REACT_APP_CDC_API_BASEURL}/v2/cdc/groups/getMembers`;
          axios
            .post(
              process.env.REACT_APP_LOCALE === "zhTW"
                ? GetMembersUrlTW
                : GetMembersUrl,
              {
                apiKey,
                groupId: this.props.clinicInfo!.groupId,
              },
              { headers },
            )
            .then((res) => {
              this.setState({
                members: res.data.results.filter(
                  (result: { relationshipData: { status: string } }) =>
                    result.relationshipData.status !== "pending",
                ),
                updatedMembers: res.data.results.filter(
                  (result: { relationshipData: { status: string } }) =>
                    result.relationshipData.status !== "pending",
                ),
                updatedMembersStatus: [],
                updatedMembersPermission: [],
                spinner: false,
                pendingMembers: res.data.results.filter(
                  (result: { relationshipData: { status: string } }) =>
                    result.relationshipData.status === "pending",
                ),
              });
            })
            .catch((err) => {
              this.setState({
                toast: {
                  open: true,
                  severity: "error",
                  message: "No Authorization",
                  errorDetails: err.errorDetails,
                },
                spinner: false,
              });
            });
        } else {
          this.setState({ spinner: false });
        }
      },
    });
  }

  // Update the updatedMembers state property here. It will enable the save button
  // when the object is different from the members object.
  processRowUpdate = (
    updatedRow: GroupMemberInfo,
    originalRow: GroupMemberInfo,
  ) => {
    const newMembers = this.state.updatedMembers?.map((member) => {
      if (member.UID === updatedRow.UID) {
        return updatedRow;
      }

      return member;
    });

    // CHECK FOR PERMISSION CHANGE
    if (updatedRow.permissions !== originalRow.permissions) {
      // Make sure that updatedPermissions is defined. This is done mostly to silence typescript errors
      // but updatedPermissions should always be defined in the lifecycle methods before getting to this
      // point
      const newUpdatedMembersPermission = this.state.updatedMembersPermission;
      newUpdatedMembersPermission.push(updatedRow);

      this.setState({
        updatedMembersPermission: newUpdatedMembersPermission,
        updatedMembers: newMembers,
      });
    }

    // CHECK FOR STATUS CHANGE
    if (
      updatedRow.relationshipData.status !== originalRow.relationshipData.status
    ) {
      const newUpdatedMembersStatus = this.state.updatedMembersStatus;
      newUpdatedMembersStatus.push(updatedRow);

      this.setState({
        updatedMembersStatus: newUpdatedMembersStatus,
        updatedMembers: newMembers,
      });
    }

    return updatedRow;
  };

  dropdownComponent = (params: any) => {
    const ref = useGridApiContext();
    if (params.id === this.props.user.UID) {
      // ref.current.stopCellEditMode({
      // 	id: params.id,
      // 	field: params.field,
      // });
      return (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{ opacity: "0.7" }}
        >
          {params.value}
        </div>
      );
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={(event) => {
            event.stopPropagation(); // to not select row
            ref.current.startCellEditMode({
              id: params.id,
              field: params.field,
            });
          }}
        >
          <div>{params.value}</div>
          <ArrowDropDown
            key={1}
            color="primary"
            style={{ marginLeft: "auto" }}
            onClick={(event) => {
              event.stopPropagation(); // to not select row
              ref.current.startCellEditMode({
                id: params.id,
                field: params.field,
              });
            }}
          />
        </Box>
      );
    }
  };

  renderPendingRoleCell = (params: any) => {
    return (
      <div style={{ opacity: "0.7" }}>{params.row.relationshipData.role}</div>
    );
  };

  permissionsExist = () => {
    // If the state property is undefined return false
    if (this.state.updatedMembers === undefined) {
      return false;
    }

    // If the state property is not an empty object return true
    if (Object.keys(this.state.updatedMembers).length) {
      return true;
    }

    // If the state proprty is an empty object return false
    return false;
  };

  savePermission = () => {
    //No change done then updated permissions is an empty object hence return true
    if (
      this.state.updatedMembersStatus.length > 0 ||
      this.state.updatedMembersPermission.length > 0
    ) {
      return false;
    }
    return true;
  };

  handleDeclineMember = (member: GroupMemberInfo) => {
    // MAKE REQUEST THAT UPDATES STATUS TO PENDING FOR NOW.
    // DISCUSS THE DECLINED STATUS
    window.gigya.accounts.getJWT({
      UID: this.props.user.UID ?? "",
      callback: (resp) => {
        if (resp["errorCode"] === 0) {
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${resp.id_token}`,
          };
          const apiKey = process.env.REACT_APP_CDC_API_KEY ?? "";
          const SetMemberInfoUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/setMemberInfo`;
          const SetMemberInfoUrlTW = `${process.env.REACT_APP_CDC_API_BASEURL}/v2/cdc/groups/setMemberInfo`;
          axios
            .post(
              process.env.REACT_APP_LOCALE === "zhTW"
                ? SetMemberInfoUrlTW
                : SetMemberInfoUrl,
              {
                apiKey,
                groupId: this.props.clinicInfo?.groupId,
                memberUid: member.UID,
                role: member.relationshipData.role,
                status: "rejected",
              },
              { headers },
            )
            .then((res) => {
              const newPendingMembers = this.state.pendingMembers?.filter(
                (pendingMember) => pendingMember.UID !== member.UID,
              );

              this.setState({
                spinner: false,
                pendingMembers: newPendingMembers,
                members: this.state.updatedMembers?.concat({
                  ...member,
                  relationshipData: {
                    ...member.relationshipData,
                    status: "rejected",
                  },
                }),
              });
            })
            .catch((err) => {
              this.setState({
                toast: {
                  open: true,
                  severity: "error",
                  message: err.errorMessage,
                  errorDetails: err.errorDetails,
                },
                spinner: false,
              });
            });
        } else {
          this.setState({
            spinner: false,
          });
        }
      },
    });
  };

  handleAcceptMember = (member: GroupMemberInfo) => {
    // MAKE REQUEST THAT UPDATES STATUS TO APPROVED
    window.gigya.accounts.getJWT({
      UID: this.props.user.UID ?? "",
      callback: (resp) => {
        if (resp["errorCode"] === 0) {
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${resp.id_token}`,
          };
          const apiKey = process.env.REACT_APP_CDC_API_KEY ?? "";
          const SetMemberInfoUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/setMemberInfo`;
          const SetMemberInfoUrlTW = `${process.env.REACT_APP_CDC_API_BASEURL}/v2/cdc/groups/setMemberInfo`;
          axios
            .post(
              process.env.REACT_APP_LOCALE === "zhTW"
                ? SetMemberInfoUrlTW
                : SetMemberInfoUrl,
              {
                apiKey,
                groupId: this.props.clinicInfo?.groupId,
                memberUid: member.UID,
                role: member.relationshipData.role,
                status: "approved",
              },
              { headers },
            )
            .then((res) => {
              const newPendingMembers = this.state.pendingMembers?.filter(
                (pendingMember) => pendingMember.UID !== member.UID,
              );

              this.setState({
                spinner: false,
                pendingMembers: newPendingMembers,
                members: this.state.updatedMembers?.concat({
                  ...member,
                  relationshipData: {
                    ...member.relationshipData,
                    status: "approved",
                  },
                }),
              });
            })
            .catch((err) => {
              this.setState({
                toast: {
                  open: true,
                  severity: "error",
                  message: err.errorMessage,
                  errorDetails: err.errorDetails,
                },
                spinner: false,
              });
            });
        } else {
          this.setState({
            spinner: false,
          });
        }
      },
    });
  };

  render() {
    return [
      this.state.spinner ? (
        <LoadingSpinner key={0}></LoadingSpinner>
      ) : (
        React.cloneElement(
          transformExportedDesign(
            {
              "frame-button1":
                process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                  <Button
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={() => {
                      window.gigya.accounts.groups.createInvitation({
                        model: GigyaGroupModels.ClinicModel,
                        groupId: this.props.clinicInfo!.groupId,
                        callback: (r) => {
                          if (r.errorCode) {
                            this.setState({
                              toast: {
                                open: true,
                                severity: "error",
                                message: r.errorMessage,
                                errorDetails: r.errorDetails,
                              },
                            });
                          } else {
                            this.setState({
                              modalDialog: {
                                open: true,
                                style: { height: "335px" },
                                title: this.props.t(
                                  "exported.Add A New Member",
                                ),
                                content: [
                                  <TextField
                                    key={0}
                                    fullWidth
                                    label={this.props.t(
                                      "members.Single Use Clinic Join Link",
                                    )}
                                    value={r.invitationLink}
                                    inputProps={{ readOnly: true }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Share color="primary" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />,
                                  <Typography key={1} variant="UIObjectsText4">
                                    {this.props.t("members.singleUseLinkText")}
                                  </Typography>,
                                ],
                                actions: [
                                  <Button
                                    key={0}
                                    variant="text"
                                    onClick={() => {
                                      void navigator.clipboard.writeText(
                                        `${r.invitationLink}&clinic=${this.props.clinicInfo!.groupId.toString()}`,
                                      );
                                      this.setState({
                                        toast: {
                                          open: true,
                                          severity: "success",
                                          message: this.props.t(
                                            "members.successInviteLinkToast",
                                          ),
                                        },
                                      });
                                    }}
                                  >
                                    {this.props.t("members.Copy to clipboard")}
                                  </Button>,
                                ],
                              },
                            });
                          }
                        },
                      });
                    }}
                  />
                ) : (
                  "removeAll"
                ),
              "frame-button3": (
                <Button
                  disabled={this.savePermission()}
                  variant="text"
                  onClick={() => {
                    this.setState({ spinner: true });
                    this.fetchMembers();
                    this.setState({ spinner: false });
                  }}
                />
              ),
              "frame-button4": (
                <Button
                  disabled={this.savePermission()}
                  onClick={() => {
                    const sleep = async (ms: number) =>
                      new Promise((r) => {
                        setTimeout(r, ms);
                      });

                    const promiseArrPermissions = [
                      this.state.updatedMembersPermission.map(
                        async (updatedMemberPermission) => {
                          window.gigya.accounts.getJWT({
                            UID: this.props.user.UID ?? "",
                            callback: (resp) => {
                              if (resp["errorCode"] === 0) {
                                const headers = {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${resp.id_token}`,
                                };
                                const apiKey =
                                  process.env.REACT_APP_CDC_API_KEY ?? "";
                                const SetMemberInfoUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/setMemberInfo`;
                                const SetMemberInfoUrlTW = `${process.env.REACT_APP_CDC_API_BASEURL}/v2/cdc/groups/setMemberInfo`;
                                axios
                                  .post(
                                    process.env.REACT_APP_LOCALE === "zhTW"
                                      ? SetMemberInfoUrlTW
                                      : SetMemberInfoUrl,
                                    {
                                      apiKey,
                                      groupId: this.props.clinicInfo?.groupId,
                                      memberUid: updatedMemberPermission.UID,
                                      role: updatedMemberPermission
                                        .relationshipData.role,
                                      status:
                                        updatedMemberPermission.relationshipData
                                          .status,
                                    },
                                    { headers },
                                  )
                                  .catch((err) => {
                                    this.setState({
                                      toast: {
                                        open: true,
                                        severity: "error",
                                        message: err.errorMessage,
                                        errorDetails: err.errorDetails,
                                      },
                                      spinner: false,
                                    });
                                  });
                              } else {
                                this.setState({
                                  spinner: false,
                                });
                              }
                            },
                          });
                        },
                      ),
                    ];

                    const promiseArrStatuses =
                      this.state.updatedMembersStatus.map(
                        async (updatedMemberStatus) => {
                          window.gigya.accounts.getJWT({
                            UID: this.props.user.UID ?? "",
                            callback: (resp) => {
                              if (resp["errorCode"] === 0) {
                                const headers = {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${resp.id_token}`,
                                };
                                const apiKey =
                                  process.env.REACT_APP_CDC_API_KEY ?? "";
                                const SetMemberInfoUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/setMemberInfo`;
                                const SetMemberInfoUrlTW = `${process.env.REACT_APP_CDC_API_BASEURL}/v2/cdc/groups/setMemberInfo`;
                                axios
                                  .post(
                                    process.env.REACT_APP_LOCALE === "zhTW"
                                      ? SetMemberInfoUrlTW
                                      : SetMemberInfoUrl,
                                    {
                                      apiKey,
                                      groupId: this.props.clinicInfo?.groupId,
                                      memberUid: updatedMemberStatus.UID,
                                      role: updatedMemberStatus.relationshipData
                                        .role,
                                      status:
                                        updatedMemberStatus.relationshipData
                                          .status,
                                    },
                                    { headers },
                                  )
                                  .catch((err) => {
                                    this.setState({
                                      toast: {
                                        open: true,
                                        severity: "error",
                                        message: err.errorMessage,
                                        errorDetails: err.errorDetails,
                                      },
                                      spinner: false,
                                    });
                                  });
                              } else {
                                this.setState({
                                  spinner: false,
                                });
                              }
                            },
                          });
                        },
                      );

                    Promise.all([
                      ...promiseArrPermissions,
                      ...promiseArrStatuses,
                    ])
                      .then(() => {
                        this.setState({
                          updatedMembersStatus: [],
                          updatedMembersPermission: [],
                          spinner: false,
                        });
                      })
                      .catch((r: ResponseSystemFields) => {
                        this.setState({
                          toast: {
                            open: true,
                            severity: "error",
                            message: r.errorMessage,
                            errorDetails: r.errorDetails,
                          },
                          spinner: false,
                        });
                      });
                  }}
                />
              ),
              table: this.state?.members ? (
                <div style={{ width: "100%" }}>
                  <DataGrid
                    // sx={{'.MuiDataGrid-main': {width: '100%'}}}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 7,
                        },
                      },
                    }}
                    getRowId={(m) => m.UID}
                    rows={this.state?.members}
                    columnBuffer={4}
                    processRowUpdate={(updatedRow, originalRow) =>
                      this.processRowUpdate(updatedRow, originalRow)
                    }
                    columns={[
                      {
                        flex: 1,
                        headerName: this.props.t("exported.Name"),
                        field: "fullName",
                        valueGetter: (p) =>
                          `${p.row.profile.lastName as string}, ${p.row.profile.firstName as string}`,
                      },
                      {
                        flex: 0.3,
                        headerName: this.props.t("status"),
                        field: "status",
                        type: "singleSelect",
                        editable: true,
                        valueOptions: () => {
                          return [
                            {
                              value: "approved",
                              label: this.props.t("approved"),
                            },
                            {
                              value: "rejected",
                              label: this.props.t("rejected"),
                            },
                          ];
                        },
                        valueGetter: (p) =>
                          `${p.row.relationshipData.status as string}`,
                        valueSetter: (p) => {
                          return {
                            ...p.row,
                            relationshipData: {
                              ...p.row.relationshipData,
                              status: p.value,
                            },
                          };
                        },
                        renderCell: this.dropdownComponent,
                      },
                      {
                        flex: 0.3,
                        headerName: this.props.t("exported.Role"),
                        field: "admin",
                        type: "singleSelect",
                        editable: true,
                        valueOptions: (p) => {
                          if (p?.row?.UID != this.props.user.UID) {
                            return [
                              {
                                value: "admin",
                                label: this.props.t("exported.Admin"),
                              },
                              {
                                value: "member",
                                label: this.props.t("exported.Member"),
                              },

                              {
                                value: "employee",
                                label: this.props.t("exported.Employee"),
                              },
                            ];
                          } else {
                            return [
                              {
                                value: "admin",
                                label: this.props.t("exported.Admin"),
                              },
                            ];
                          }
                        },
                        valueGetter: (p) => {
                          if (p.row.relationshipData.role === "admin")
                            return this.props.t("exported.Admin");
                          if (p.row.relationshipData.role === "member")
                            return this.props.t("exported.Member");
                          if (p.row.relationshipData.role === "employee")
                            return this.props.t("exported.Employee");
                        },
                        valueSetter: (p) => {
                          const newPermissions =
                            p.value === "admin" || p.value === "employee"
                              ? adminPermissions
                              : memberPermissions;
                          return {
                            ...p.row,
                            permissions: newPermissions,
                            relationshipData: {
                              ...p.row.relationshipData,
                              role: p.value,
                            },
                          };
                        },
                        renderCell: this.dropdownComponent,
                      },
                      {
                        flex: 1,
                        headerName: this.props.t("exported.Email"),
                        field: "email",
                        valueGetter: (p) => p.row.profile.email,
                      },
                      {
                        flex: 0.15,
                        headerName: "",
                        field: "action",
                        type: "actions",
                        getActions: (p) => [
                          <GridActionsCellItem
                            className="member-remove-trash"
                            id={`member-remove-trash-${p.id}`}
                            key="delete"
                            icon={<TrashSvg />}
                            sx={{
                              "&.Mui-disabled": {
                                pointerEvents: "auto",
                              },
                            }}
                            disabled={
                              (p.row.relationshipData.role === "admin" ||
                                p.row.relationshipData.role === "employee") &&
                              p.row.UID === this.props.user.UID
                                ? true
                                : false
                            }
                            title={
                              p.row.relationshipData.role === "admin" &&
                              p.row.UID === this.props.user.UID
                                ? this.props.t("members.warningAdmin")
                                : this.props.t("members.deleteUser")
                            }
                            label={this.props.t("members.deleteUser")}
                            onClick={() => {
                              this.setState({
                                modalDialog: {
                                  open: true,
                                  style: {
                                    height: "640px",
                                  },
                                  title: this.props
                                    .t(
                                      "members.Are you sure you want to delete this member from “College blvd Animal Hospital”?",
                                    )
                                    .replace(
                                      "College blvd Animal Hospital",
                                      this.props.clinicInfo!.groupData
                                        .clinicName as string,
                                    ),
                                  content: [
                                    <Typography
                                      key={0}
                                      variant="UIObjectsText1"
                                    >{`${this.props.t("exported.Name")}: ${p.row.profile.lastName as string}, ${p.row.profile.firstName as string}`}</Typography>,
                                    <Typography
                                      key={1}
                                      variant="UIObjectsText1"
                                    >{`${this.props.t("exported.Role")}: ${isMemberAdmin(p.row.permissions) ? this.props.t("exported.Admin") : this.props.t("exported.Member")}`}</Typography>,
                                    <Typography
                                      key={2}
                                      variant="UIObjectsText1"
                                    >{`${this.props.t("exported.Email")}: ${p.row.profile.email as string}`}</Typography>,
                                    <Typography
                                      key={3}
                                      variant="UIObjectsText1"
                                    >
                                      {this.props.t(
                                        "members.deleteMemberText1",
                                      )}
                                    </Typography>,
                                    <Typography
                                      key={4}
                                      variant="UIObjectsText1"
                                    >
                                      {this.props.t(
                                        "members.deleteMemberText2",
                                      )}
                                    </Typography>,
                                  ],
                                  actions: [
                                    <Button
                                      key={0}
                                      variant="text"
                                      onClick={this.handleModalDialogClose}
                                    >
                                      {this.props.t("exported.Cancel")}
                                    </Button>,
                                    <Button
                                      key={1}
                                      variant="text"
                                      onClick={() => {
                                        this.setState({
                                          spinner: true,
                                        });
                                        const removedUsers = [
                                          ...new Set([
                                            ...(this.props.clinicInfo!.groupData
                                              .removedUsers ?? []),
                                            p.row.UID,
                                          ]),
                                        ];
                                        Promise.all([
                                          new Promise((s, e) => {
                                            this.setState({
                                              modalDialog: {
                                                open: false,
                                              },
                                            });
                                            const val =
                                              this.state.members?.filter(
                                                (item) =>
                                                  item.relationshipData.role !=
                                                  undefined,
                                              );
                                            if (
                                              val != undefined &&
                                              val?.length > 0
                                            ) {
                                              window.gigya.accounts.getJWT({
                                                UID: val[0].UID ?? "",
                                                callback: (res) => {
                                                  if (res["errorCode"] === 0) {
                                                    const headers = {
                                                      "Content-Type":
                                                        "application/json",
                                                      Authorization: `Bearer ${res.id_token}`,
                                                    };
                                                    const apiKey =
                                                      process.env
                                                        .REACT_APP_CDC_API_KEY ??
                                                      "";
                                                    const RemoveMemberUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/removeMember`;
                                                    const RemoveMemberUrlTW = `${process.env.REACT_APP_CDC_API_BASEURL}/v2/cdc/groups/removeMember`;
                                                    axios
                                                      .post(
                                                        process.env
                                                          .REACT_APP_LOCALE ===
                                                          "zhTW"
                                                          ? RemoveMemberUrlTW
                                                          : RemoveMemberUrl,
                                                        {
                                                          apiKey,
                                                          groupId:
                                                            this.props
                                                              .clinicInfo
                                                              ?.groupId,
                                                          emailId:
                                                            p.row.profile.email,
                                                        },
                                                        {
                                                          headers,
                                                        },
                                                      )
                                                      .then(() => {
                                                        setTimeout(() => {
                                                          this.fetchMembers();
                                                          this.setState({
                                                            spinner: false,
                                                          });
                                                        }, 3000);
                                                      })
                                                      .catch(
                                                        (
                                                          r: ResponseSystemFields,
                                                        ) => {
                                                          this.setState({
                                                            toast: {
                                                              open: true,
                                                              severity: "error",
                                                              message:
                                                                r.errorMessage,
                                                              errorDetails:
                                                                r.errorDetails,
                                                            },
                                                            modalDialog: {
                                                              open: false,
                                                            },
                                                          });
                                                          setTimeout(() => {
                                                            this.fetchMembers();
                                                            this.setState({
                                                              spinner: false,
                                                            });
                                                          }, 3000);
                                                        },
                                                      );
                                                  }
                                                },
                                              });
                                            } else {
                                              this.setState({
                                                spinner: false,
                                              });
                                            }
                                          }),
                                          new Promise((s, e) => {
                                            window.gigya.accounts.groups.setGroupInfo(
                                              {
                                                model:
                                                  GigyaGroupModels.ClinicModel,
                                                groupId:
                                                  this.props.clinicInfo!
                                                    .groupId,
                                                groupData: {
                                                  removedUsers,
                                                },
                                                callback(r) {
                                                  (r.errorCode ? e : s)(r);
                                                },
                                              },
                                            );
                                          }),
                                        ])
                                          .then(() => {
                                            this.props.clinicInfo!.groupData.removedUsers =
                                              removedUsers;
                                            this.setState({
                                              updatedMembers:
                                                this.state.updatedMembers!.filter(
                                                  (v) => v.UID !== p.row.UID,
                                                ),
                                              modalDialog: {
                                                open: false,
                                              },
                                              spinner: false,
                                            });
                                          })
                                          .catch((r: ResponseSystemFields) => {
                                            this.setState({
                                              toast: {
                                                open: true,
                                                severity: "error",
                                                message: r.errorMessage,
                                                errorDetails: r.errorDetails,
                                              },
                                              modalDialog: {
                                                open: false,
                                              },
                                              spinner: false,
                                            });
                                          });
                                      }}
                                    >
                                      {this.props.t("members.Delete")}
                                    </Button>,
                                  ],
                                },
                              });
                            }}
                          />,
                        ],
                      },
                    ]}
                  />
                </div>
              ) : (
                <Skeleton height={50} />
              ),
              table2: this.state?.members ? (
                <div style={{ height: 215, width: "100%" }}>
                  <DataGrid
                    // sx={{'.MuiDataGrid-main': {width: '100%'}}}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 7,
                        },
                      },
                    }}
                    getRowId={(m) => m.UID}
                    rows={this.state?.pendingMembers || []}
                    columnBuffer={4}
                    processRowUpdate={(updatedRow, originalRow) =>
                      this.processRowUpdate(updatedRow, originalRow)
                    }
                    columns={[
                      {
                        flex: 1,
                        headerName: this.props.t("exported.Name"),
                        field: "fullName",
                        valueGetter: (p) =>
                          `${p.row.profile.lastName as string}, ${p.row.profile.firstName as string}`,
                      },
                      {
                        flex: 0.3,
                        headerName: this.props.t("exported.Role"),
                        field: "admin",
                        renderCell: this.renderPendingRoleCell,
                      },
                      {
                        flex: 1,
                        headerName: this.props.t("exported.Email"),
                        field: "email",
                        valueGetter: (p) => p.row.profile.email,
                      },
                      {
                        flex: 0.5,
                        headerName: "",
                        field: "decline",
                        type: "actions",
                        getActions: (p) => [
                          <GridActionsCellItem
                            className="member-remove-trash"
                            id={`member-decline-${p.id}`}
                            key="decline"
                            icon={
                              <Button
                                variant="outlined"
                                sx={{
                                  color: "#f44336",
                                  border: "2px solid #f44336",
                                }}
                              >
                                {this.props.t("reject")}
                              </Button>
                            }
                            label={this.props.t("members.deleteUser")}
                            onClick={() => {
                              this.handleDeclineMember(p.row);
                            }}
                          />,
                        ],
                      },
                      {
                        flex: 0.5,
                        headerName: "",
                        field: "approve",
                        type: "actions",
                        getActions: (p) => [
                          <GridActionsCellItem
                            className="member-remove-trash"
                            id={`member-approve-${p.id}`}
                            key="approve"
                            icon={
                              <Button
                                variant="outlined"
                                sx={{
                                  color: "#66bb6a",
                                  border: "2px solid #66bb6a",
                                }}
                              >
                                {this.props.t("approve")}
                              </Button>
                            }
                            label={this.props.t("members.deleteUser")}
                            onClick={() => {
                              this.handleAcceptMember(p.row);
                            }}
                          />,
                        ],
                      },
                    ]}
                  />
                </div>
              ) : (
                <Skeleton height={50} />
              ),
              column: "removeAll",
              frame331: "removeAll",
              "frame-button2": "removeAll",
            },
            P11440322HGMHomeAdminViewmemberstabedit(),
          ),
          { key: 0 },
        )
      ) /* eslint-disable-line new-cap */,
      <ModalDialog
        key={1}
        open={this.state?.modalDialog?.open ?? false}
        onClose={this.handleModalDialogClose}
        style={this.state?.modalDialog?.style}
        title={this.state?.modalDialog?.title ?? ""}
        content={this.state?.modalDialog?.content ?? <></>}
        actions={this.state?.modalDialog?.actions ?? []}
      />,
      <TableMembers key={2} />,
    ];
  }
}

export default withTranslation()(GroupMembers);
